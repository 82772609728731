import React from "react";
import { useWindowSize } from "./hooks/Window";

export const GetUserName = () => {
  let window = useWindowSize();
  let userName = localStorage.getItem("username");
  if (window.width < 767) {
    let fixName = userName.split(" ").slice(0, 2).join(" ");
    if (fixName > 15) {
      userName = userName.slice(0, 15) + "...";
    } else {
      userName = fixName;
    }
  }
  return <>{userName}</>;
};

export const Months = [
  {
    id: 1,
    name: "Enero",
  },
  {
    id: 2,
    name: "Febrero",
  },
  {
    id: 3,
    name: "Marzo",
  },
  {
    id: 4,
    name: "Abril",
  },
  {
    id: 5,
    name: "Mayo",
  },
  {
    id: 6,
    name: "Junio",
  },
  {
    id: 7,
    name: "Julio",
  },
  {
    id: 8,
    name: "Agosto",
  },
  {
    id: 9,
    name: "Septiembre",
  },
  {
    id: 10,
    name: "Octubre",
  },
  {
    id: 11,
    name: "Noviembre",
  },
  {
    id: 12,
    name: "Diciembre",
  },
];

export const ChooseMonth = (month) => {
  const res = Months.filter((item) => item.id === month)[0].name;
  return res;
};

export const Days = (month, year) => {
  const days = new Date(year, month, 0).getDate();
  let listDays = [];
  for (let i = 1; i <= days; i++) {
    listDays.push({ id: i, name: i });
  }

  return listDays;
};

export const Years = [
  { id: "2027", name: "2027" },
  { id: "2026", name: "2026" },
  { id: "2025", name: "2025" },
  { id: "2024", name: "2024" },
  { id: "2023", name: "2023" },
  { id: "2022", name: "2022" },
  { id: "2021", name: "2021" },
  { id: "2020", name: "2020" },
  { id: "2019", name: "2019" },
  { id: "2018", name: "2018" },
  { id: "2017", name: "2017" },
  { id: "2016", name: "2016" },
  { id: "2015", name: "2015" },
  { id: "2014", name: "2014" },
  { id: "2013", name: "2013" },
  { id: "2012", name: "2012" },
  { id: "2011", name: "2011" },
  { id: "2010", name: "2010" },
  { id: "2009", name: "2009" },
];

export const Periods = [
  { id: "", name: "Todos" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8" },
  { id: "9", name: "9" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
  { id: "13", name: "13" },
  { id: "14", name: "14" },
  { id: "15", name: "15" },
  { id: "16", name: "16" },
  { id: "17", name: "17" },
  { id: "18", name: "18" },
  { id: "19", name: "19" },
  { id: "20", name: "20" },
  { id: "21", name: "21" },
  { id: "22", name: "22" },
  { id: "23", name: "23" },
  { id: "24", name: "24" },
  { id: "25", name: "25" },
  { id: "26", name: "26" },
  { id: "27", name: "27" },
  { id: "28", name: "28" },
  { id: "29", name: "29" },
  { id: "30", name: "30" },
  { id: "31", name: "31" },
  { id: "32", name: "32" },
  { id: "33", name: "33" },
  { id: "34", name: "34" },
  { id: "35", name: "35" },
  { id: "36", name: "36" },
  { id: "37", name: "37" },
  { id: "38", name: "38" },
  { id: "39", name: "39" },
  { id: "40", name: "40" },
  { id: "41", name: "41" },
  { id: "42", name: "42" },
  { id: "43", name: "43" },
  { id: "44", name: "44" },
  { id: "45", name: "45" },
  { id: "46", name: "46" },
  { id: "47", name: "47" },
  { id: "48", name: "48" },
  { id: "49", name: "49" },
  { id: "50", name: "50" },
  { id: "51", name: "51" },
  { id: "52", name: "52" },
  { id: "53", name: "53" },
  { id: "54", name: "54" },
];

export const FixSelectData = (id, name, items) => {
  let getItems = items.map((item) => {
    return {
      id: item[id],
      name: item[name],
    };
  });
  return getItems;
};

/*
 * Funciones para el manejo de archivos y form datas
 */

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/*
 * creamos un blob
 */
export const createBlob = (dataUri) => {
  let block = dataUri.split(";");
  let contentType = block[0].split(":")[1];
  let realData = block[1].split(",")[1];
  const blob = b64toBlob(realData, contentType);
  return blob;
};

/*
 * Convertimos un blob a form data
 */
export const createFormData = (
  blob,
  BlobTypeId = 1,
  ContainerName = "publicproducts",
  IsPrivate = true,
  UserId = ""
) => {
  const data = new FormData();
  data.set("BlobTypeId", BlobTypeId);
  data.set("ContainerName", ContainerName);
  data.set("IsPrivate", IsPrivate);
  //   if (localStorage.getItem("role") === "Admin") {
  //     data.set("UserId", UserId);
  //   }
  data.append("file", blob);
  return data;
};

export const fixYearOrMonth = (number) => {
  let fixNumber = Number(number);
  if (fixNumber < 10) {
    fixNumber = `0${fixNumber}`;
  }
  return fixNumber;
};
