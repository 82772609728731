import {
  POLICY_CARGANDO,
  POLICY_CHANGE_STATE,
  POLICY_CLEAN_STATE,
  POLICY_ERROR,
  POLICY_TYPES_PERIOD_LIST,
  POLICY_CREATE,
} from "./accountingPolicyTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    message_loading: "",
    error: [],
    isActionSuccess: false,
  },
  list_types_periods: [],
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POLICY_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: [],
        },
      };
    case POLICY_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
          isActionSuccess: false,
        },
      };

    case POLICY_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: [],
        },
      };
    case POLICY_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    case POLICY_CREATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: [],
          isActionSuccess: true,
        },
        policy_action_success: action.payload.data,
      };
    case POLICY_TYPES_PERIOD_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: [],
        },
        list_types_periods: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Reducer;
