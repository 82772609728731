import { axios_api } from "../../components-api/ConfigApi";

export const POLICY_CARGANDO = "POLICY_CARGANDO";
export const POLICY_ERROR = "POLICY_ERROR";
export const POLICY_CLEAN_STATE = "POLICY_CLEAN_STATE";
export const POLICY_CHANGE_STATE = "POLICY_CHANGE_STATE";
export const POLICY_TYPES_PERIOD_LIST = "POLICY_TYPES_PERIOD_LIST";
export const POLICY_CREATE = "POLICY_CREATE";

export const GetTypesPeriod = async () => {
  const answer = await axios_api(`/contpaqi/types-period/`, "company", "get");
  return { res: answer, type: POLICY_TYPES_PERIOD_LIST };
};

export const PolicyCreate = async (dataJson) => {
  const formData = new FormData();

  for (const key in dataJson) {
    if (Array.isArray(dataJson[key])) {
      dataJson[key].forEach((value, index) => {
        formData.append(`${key}[${index}]`, value);
      });
    } else {
      formData.append(key, dataJson[key]);
    }
  }

  const answer = await axios_api(
    `/contpaqi/policies/`,
    "company",
    "post",
    formData,
    10000000
  );
  return { res: answer, type: POLICY_CREATE };
};
