import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StyledComponents, Dropdown, Utils } from "../components-config";

import * as integrityActions from "./reducer/integrityActions";
import * as cfdisActions from "../components-cfdis/reducer/cfdisActions";

export const SelectCompanies = () => {
  //const {idCompany} = useParams();

  const integrityReducer = useSelector((state) => state.integrityReducer);
  localStorage.setItem("x-app-cocia", integrityReducer.company_selected.id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(integrityActions.integrityMethods("", "GetCompanies"));
  }, [dispatch]);

  let fixSelect;
  if (integrityReducer.companies_list) {
    fixSelect = Utils.FixSelectData(
      "code",
      "name",
      integrityReducer.companies_list
    );

    if (
      fixSelect.length === 1 &&
      integrityReducer.company_selected.id + "-" + integrityReducer.company_selected.name === "Seleccione una opción"
    ) {
      dispatch(integrityActions.changeState("company_selected", fixSelect[0]));
    }
  }

  const setValue = (item) => {
    dispatch(
      integrityActions.changeState("module_selected", {
        id: "0",
        name: "Elija un módulo...",
      })
    );
    dispatch(
      integrityActions.changeState("sub_module_selected", {
        id: "0",
        name: "Elija un sub-módulo...",
      })
    );
    // if (integrityReducer.company_selected.id) {
    // debugger
    // dispatch(
    //   integrityActions.integrityMethods(
    //     { company: integrityReducer.company_selected.id },
    //     "GetModules"
    //   )
    // );
    // }
    dispatch(integrityActions.changeState("sub_modules_list", []));
    //history.push(`/dashboard/${item.id}`)
  };
  return (
    <>
      <Dropdown.Select
        reducer={"integrityReducer"}
        reducerValue="company_selected"
        maxWidthToggle="300px"
        paddingToggle="15px 5px"
        colorArrow="var(--purple40)"
        items={fixSelect}
        setValue={setValue}
        changeState={integrityActions.changeState}
        bgColor="var(--grey50)"
      ></Dropdown.Select>
    </>
  );
};

export const SelectCompaniesUser = () => {
  const integrityReducer = useSelector((state) => state.integrityReducer);

  return (
    <>
      {integrityReducer.company_selected.name === "Seleccione una opción" &&
        window.location.pathname === "/dashboard" && (
          <>
            <section>
              <Container>
                <StyledComponents.SpecialContainer margin="60px 0 0 0">
                  <StyledComponents.MyP
                    fontSize="18px"
                    className="font-weight-bold text-purple40"
                  >
                    ¡Hola, {localStorage.getItem("username")}!
                  </StyledComponents.MyP>
                  <StyledComponents.MyP
                    fontSize="14px"
                    className="text-purple40"
                  >
                    Por favor, elija la compañia con la que se desea trabajar:
                  </StyledComponents.MyP>
                  <StyledComponents.SpecialContainer margin="20px 0 0 0">
                    <SelectCompanies />
                  </StyledComponents.SpecialContainer>
                </StyledComponents.SpecialContainer>
              </Container>
            </section>
          </>
        )}
    </>
  );
};

export const SelectModules = () => {
  // const history = useHistory();
  // const {idCompany} = useParams();
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected } = integrityReducer;

  const dispatch = useDispatch();
  useEffect(() => {
    const model = { company: company_selected.id };

    if (model.company) {
      dispatch(integrityActions.integrityMethods(model, "GetModules"));
    }
  }, [dispatch, company_selected]);

  let fixSelect;
  if (integrityReducer.modules_list) {
    fixSelect = Utils.FixSelectData(
      "identifier",
      "name",
      integrityReducer.modules_list
    );
  }

  const setValue = (item) => {
    dispatch(
      integrityActions.changeState("sub_module_selected", {
        id: "0",
        name: "Elija un sub-módulo...",
      })
    );
    //history.push(`/dashboard/${idCompany}/${item.id}`)
  };

  return (
    <>
      <Dropdown.Select
        reducer={"integrityReducer"}
        reducerValue="module_selected"
        maxWidthToggle="220px"
        items={fixSelect}
        setValue={setValue}
        selectClass="select-module"
        colorArrow="white"
        changeState={integrityActions.changeState}
        bgColor="var(--grey50)"
      ></Dropdown.Select>
    </>
  );
};
export const SelectSubModules = () => {
  const history = useHistory();
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected, module_selected } = integrityReducer;
  const dispatch = useDispatch();
  useEffect(() => {
    if (module_selected) {
      const model = {
        module: module_selected.id,
        //company: company_selected.id,
      };
      dispatch(integrityActions.integrityMethods(model, "GetSubModules"));
    }
  }, [dispatch, module_selected, company_selected]);

  let fixSelect;
  if (integrityReducer.sub_modules_list) {
    fixSelect = Utils.FixSelectData(
      "identifier",
      "name",
      integrityReducer.sub_modules_list
    );
  }

  function isCmsSubmodule(subModule) {
    return subModule.id.startsWith('cms_');
  }

  const setValue = (item) => {
    integrityReducer.url = integrityReducer.sub_modules_list.filter(
      (subModule) => subModule.identifier === item.id
    )[0].url;
    integrityReducer.sub_module_selected = item;

    // Check if the submodule is CMS then get the value of the object and
    // change the browser's URL
    if (isCmsSubmodule(item)) {
      history.push("/dashboard");

      setTimeout(() => {
        history.push(integrityReducer.url);
      }, "300");

      return;
    }

    switch (item.id) {
      case "cafeteria":
        history.push("/dashboard-cafeteria");
        break;
      case "settlement":
        history.push("/dashboard-settlements");
        break;
      case "predefined":
        history.push("/dashboard-reports");
        break;
      case "query":
          history.push("/dashboard-reports-queries");
        break;
      case "incidence_load":
        history.push("/dashboard-incidents");
        break;
      case "retrieve":
        history.push("/dashboard-cfdis");
        break;
      case "stamp_balances":
        history.push("/dashboard-stamp-balances");
        break;
      case "guruv1":
        history.push("/dashboard-ai-tool-guru-v1");
        break;
      case "guruv2":
        history.push("/dashboard-ai-tool-guru-v2");
        break;
      case "facturas":
        history.push("/dashboard-digitalizacion-feduro");
        break;
      case "accounting-policies":
          history.push("/dashboard-accounting-policies");
          break;
      default:
        history.push("/dashboard");

        break;
    }
  };

  return (
    <>
      <Dropdown.Select
        reducer={"integrityReducer"}
        reducerValue="sub_module_selected"
        maxWidthToggle="220px"
        colorArrow="var(--purple40)"
        items={fixSelect}
        setValue={setValue}
        selectClass="select-sub-module"
        changeState={integrityActions.changeState}
        bgColor="var(--grey50)"
      ></Dropdown.Select>
    </>
  );
};

export const RedirectSelects = () => {
  const history = useHistory();
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected, sub_module_selected, module_selected } =
    integrityReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      company_selected.id === "0" ||
      module_selected.id === "0" ||
      sub_module_selected.id === "0"
    ) {
      dispatch(cfdisActions.cfdisCleanState());
      history.push("/dashboard");
    }
  }, [
    dispatch,
    company_selected,
    module_selected,
    sub_module_selected,
    history,
  ]);
  // if (
  //   company_selected.id === "0" ||
  //   module_selected.id === "0" ||
  //   sub_module_selected.id === "0"
  // ) {
  //   //clean && clean()
  //   //dispatch(cfdisActions.cfdisCleanState());
  //   history.push("/dashboard");
  // }
};
