// Imports de redux.
import { combineReducers } from "redux";

// Reducers de session
import loginReducer from "../components-session/reducer/loginReducer";

// Reducers de componentes.
import cafeteriaReducer from "../components-cafeteria/reducer/cafeteriaReducer";
import emailReducer from "../components-email/reducer/emailReducer";
import payrollReducer from "../componentes-payroll/reducer/payrollReducer";
import integrityReducer from "../components-integrity/reducer/integrityReducer";
import settlementReducer from "../components-settlement/reducer/settlementReducer";
import reportReducer from "../components-reports/reducer/reportReducer";
import reportQueryReducer from "../components-report-query/reducers/index";
import downloadReducer from "../components-download/reducer/downloadReducer";
import incidentsReducer from "../components-incidents/reducer/incidentsReducer";
import cfdisReducer from "../components-cfdis/reducer/cfdisReducer";
import cfdiStampBalanceReducer from "../components-stamp-balances/reducer/CFDIStampBalanceReducer";
import cmsReducer from "../features/cms/store/rootReducer";
import digitalizationReducer from "../components-digitalization/store/digitalizationReducer";
import accountingPolicyReducer from "../components-polizas/store/accountingPolicyReducer";

export default combineReducers({
  // Reducers de sesión.
  loginReducer,
  cafeteriaReducer,
  emailReducer,
  payrollReducer,
  integrityReducer,
  settlementReducer,
  reportReducer,
  reportQueryReducer,
  downloadReducer,
  incidentsReducer,
  cfdisReducer,
  cfdiStampBalanceReducer,
  cmsReducer,
  digitalizationReducer,
  accountingPolicyReducer,
});
