import {
  POLICY_CARGANDO,
  POLICY_CHANGE_STATE,
  POLICY_CLEAN_STATE,
  POLICY_ERROR,
  PolicyCreate,
  GetTypesPeriod,
} from "./accountingPolicyTypes";

import { errorHandler } from "../../components-api/ConfigApi";

export const cleanState = () => (dispatch) => {
  dispatch({
    type: POLICY_CLEAN_STATE,
  });
};

export const changeState = (variable, value) => (dispatch) => {
  dispatch({
    type: POLICY_CHANGE_STATE,
    payload: {
      variable,
      value,
    },
  });
};

export const policyMethods = (data, method) => async (dispatch) => {
  dispatch({
    type: POLICY_CARGANDO,
  });
  try {
    let answer;
    switch (method) {
      case "GetTypesPeriod":
        answer = await GetTypesPeriod(data);
        break;
      case "PolicyCreate":
        answer = await PolicyCreate(data);
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: POLICY_ERROR,
      payload: messageError,
    });
  }
};
