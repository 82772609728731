import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Assets, Layouts, Modals, StyledComponents } from "../components-config";
import { InputFile } from "./InputFile";
import * as policyActions from "./store/accountingPolicyActions";
import { DropDownSelectSearch } from "./DropDownSelectSearch";

export const Information = () => {
  return (
    <>
      <Layouts.Info>
        <div>
          <StyledComponents.MyP fontSize="14px" className="font-weight-bold text-purple40">
            Hola, {localStorage.getItem("username")}.
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
          Selecciona un tipo de nómina, ingresa el año del ejercicio y el número de período para generar una póliza.
          </StyledComponents.MyP>
        </div>
      </Layouts.Info>
    </>
  );
};

export const Form = () => {
  const dispatch = useDispatch();
  const accountingPolicyReducer = useSelector((state) => state.accountingPolicyReducer);
  const [step, setStep] = useState({
    stepOneisComplete: false,
    stepTwoisComplete: false,
    stepThreeIsComplete: false,
  });

  const [isAlredyProcessed, setIsAlredyProcessed] = useState(false);
  const [payrollType, setPayroll] = useState({});
  const [yearExercise, setYearExercise] = useState("");
  const [periodNumber, setPeriodNumber] = useState("");
  const [overflow, setOverflow] = useState(false);
  const [messageError, setMessageError] = useState({});
  const [viewError, setViewError] = useState(false);

  const {
    list_types_periods,
    policy_action_success,
    api_actions: { cargando, isActionSuccess, error },
  } = accountingPolicyReducer;

  useEffect(() => {
    dispatch(policyActions.policyMethods({}, "GetTypesPeriod"));
  }, []);

  useEffect(() => {
    if (
      Object.keys(payrollType).length > 0 &&
      yearExercise.trim() !== "" &&
      periodNumber.trim() !== ""
    ) {
      setStep({ ...step, stepOneisComplete: true });
    } else {
      setStep({
        ...step,
        stepOneisComplete: false,
        stepTwoisComplete: false,
        stepThreeIsComplete: false,
      });
      setViewError(false);
      setIsAlredyProcessed(false);
    }
  }, [payrollType, yearExercise, periodNumber]);

  useEffect(() => {}, [isAlredyProcessed, isActionSuccess, error]);

  useEffect(() => {
    setOverflow(list_types_periods.length > 10 ? true : false);
  }, [list_types_periods]);


  function handlerChangeTypePeriods(data) {
    if (data.id != "") {
      setPayroll(data);
    } else {
      setStep({
        ...step,
        stepOneisComplete: false,
        stepTwoisComplete: false,
        stepThreeIsComplete: false,
      });
    }
  }

  function handleClickProcess(e) {
    const data = {
      email: localStorage.getItem("email"),
      payroll_type: payrollType.id,
      year_exercise: yearExercise,
      period_number: periodNumber,
    };
    dispatch(policyActions.policyMethods(data, "PolicyCreate")).then(
      (data) => {
        setIsAlredyProcessed(true);
        setStep({ ...step, stepThreeIsComplete: true });
      }
    );
  }

  function handlerOnClickProcessOther() {
    setStep({
      stepOneisComplete: false,
      stepTwoisComplete: false,
      stepThreeIsComplete: false,
    });
    // setFileNames([]);
    setIsAlredyProcessed(false);
    setYearExercise("");
    setPeriodNumber("");
  }

  function splitCamelCaseAndCapitalize(text) {
    return text
      .split(/(?=[A-Z])/)
      .join(" ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const renderErrors = (errors) => {
    if (!errors || Object.keys(errors).length === 0 || typeof errors === "string") {
      return (
        <div style={{ fontSize: "12px" }} className={`text-danger`}>
          <p className="font-weight-bold">
            Error: Hemos encontrado un problema inesperado o la información ingresada no es válida.
            Por favor, revisa los datos e inténtalo nuevamente más tarde.
          </p>
        </div>
      );
    }

    return (
      <div style={{ fontSize: "12px" }} className={`text-danger`}>
        {Object.entries(errors).map(([key, messages]) => (
          <p key={key} className="font-weight-bold">
            - {splitCamelCaseAndCapitalize(key)}: {messages.join(", ")}
          </p>
        ))}
      </div>
    );
  };

  const renderNotifications = (notifications) => (
    <div style={{ fontSize: "12px" }}>
      Revisa las notificaciones:
      {notifications.map((message, index) => (
        <p key={index} className="font-weight-bold">
          - {message}
        </p>
      ))}
    </div>
  );

  return (
    <>
      {cargando && (
        <Modals.ModalLoading message="Un momento, por favor, el documento está siendo procesado…" />
      )}

      <Information />
      <Layouts.Card
        margin="4px 0 0 0"
        padding="40px 20px"
        borderRadius="0 0 20px 20px"
        className="text-purple40">
        <StyledComponents.SpecialContainer>
          {/* Step 01 */}
          <StyledComponents.SpecialContainer className="d-flex">
            <StyledComponents.SpecialContainer className="d-flex" style={{ gap: "20px" }}>
              <StyledComponents.SpecialContainer
                className="l-flex flex-column"
                style={{ width: "460px" }}>
                <StyledComponents.MyP
                  fontSize="14px"
                  className="mb10 font-weight-bold text-purple40">
                  Tipos de Nómina
                </StyledComponents.MyP>
                <DropDownSelectSearch
                  dropdownTop="35px"
                  group={list_types_periods}
                  onChange={handlerChangeTypePeriods}
                  width="460px"
                  overflow={overflow}
                  disabled={isAlredyProcessed && isActionSuccess}
                />
              </StyledComponents.SpecialContainer>

              <StyledComponents.SpecialContainer
                className="l-flex flex-column"
                style={{ width: "260px" }}>
                <StyledComponents.MyP
                  fontSize="14px"
                  className="mb10 font-weight-bold text-purple40 ml8">
                  Año Ejercio:
                </StyledComponents.MyP>
                <StyledComponents.MyInput
                  value={yearExercise}
                  onChange={(e) => {
                    setYearExercise(e.target.value);
                  }}
                  borderRadius="40px"
                  textAlign="left"
                  className={"bg-grey130"}
                  margin="0px 4px 0px"
                  placeholder="Año Ejercio"
                  fontSize="12px"
                  width="260px"
                  padding="10px"
                  disabled={isAlredyProcessed && isActionSuccess}></StyledComponents.MyInput>
              </StyledComponents.SpecialContainer>

              <StyledComponents.SpecialContainer
                className="l-flex flex-column"
                style={{ width: "260px" }}>
                <StyledComponents.MyP
                  fontSize="14px"
                  className="mb10 font-weight-bold text-purple40 ml8">
                  Número de Periodo:
                </StyledComponents.MyP>
                <StyledComponents.MyInput
                  value={periodNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setPeriodNumber(value);
                    }
                  }}
                  borderRadius="40px"
                  textAlign="left"
                  className={"bg-grey130"}
                  margin="0px 4px 0px"
                  placeholder="Número de Periodo"
                  fontSize="12px"
                  width="260px"
                  padding="10px"
                  disabled={isAlredyProcessed && isActionSuccess}></StyledComponents.MyInput>
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>

            {step.stepOneisComplete && (
              <Assets.IconCheck color="var(--green50)" colorCheck="black" size="32" />
            )}
          </StyledComponents.SpecialContainer>
          <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
        </StyledComponents.SpecialContainer>

        {/* Step 02 */}
        <StyledComponents.SpecialContainer className={step.stepOneisComplete ? "" : "disabled"}>
          <StyledComponents.MyP fontSize="14px" className="mb10 font-weight-bold text-purple40">
            Crear póliza contable
          </StyledComponents.MyP>
          <StyledComponents.SpecialContainer className="d-flex align-items-center justify-content-between">
            <StyledComponents.MyButton
              width="160px"
              height={"28px"}
              padding="0 20px"
              margin="0"
              className={`${
                isAlredyProcessed && isActionSuccess
                  ? "btn-disabled"
                  : step.stepOneisComplete
                  ? false
                  : "btn-disabled"
              } bg-green50 text-grey80 font-weight-bold`}
              disabled={
                isAlredyProcessed && isActionSuccess ? true : step.stepOneisComplete ? false : true
              }
              onClick={(e) => handleClickProcess(e)}>
              <span className="mr10">Generar Póliza</span>
              <Assets.IconUpload />
            </StyledComponents.MyButton>
            <div style={{ width: "32px" }}>
              {step.stepThreeIsComplete &&
                (isActionSuccess ? (
                  <Assets.IconCheck color="var(--green50)" size="32px" colorCheck="black" />
                ) : (
                  <Assets.IconError color="var(--red70)" fill="black" size="32px" />
                ))}
            </div>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
      <Container>
        {viewError && (
          <div style={{ fontSize: "12px" }} className={`text-danger`}>
            <StyledComponents.MyP
              fontSize="14px"
              className="mt20 font-weight-bold"
              style={{ color: "black" }}>
              Resumen:
            </StyledComponents.MyP>
            {renderErrors(messageError)}
          </div>
        )}
        {isAlredyProcessed && !viewError && (
          <StyledComponents.SpecialContainer>
            <StyledComponents.MyP
              fontSize="14px"
              className="mt20 font-weight-bold"
              style={{ color: "black" }}>
              Resumen:
            </StyledComponents.MyP>
            {isActionSuccess ? (
              <div style={{ fontSize: "12px" }}>
                {policy_action_success?.message}
                {policy_action_success?.notifications &&
                  policy_action_success.notifications.length > 0 &&
                  renderNotifications(policy_action_success?.notifications)}
              </div>
            ) : (
              <div style={{ fontSize: "12px" }} className={`text-danger`}>
                El documento NO ha sido creado de manera correcta debido a lo siguiente:
                {renderErrors(error)}
              </div>
            )}

            <StyledComponents.MyButton
              maxWidth={"160px"}
              height={"28px"}
              className={`mt20 d-flex align-items-center justify-content-center bg-purple20 text-white font-weight-bold`}
              padding={"6px"}
              style={{ fontSize: "12px" }}
              onClick={handlerOnClickProcessOther}>
              <div className={"mr10"}>Generar Póliza </div>
              <Assets.IconArrowClockWise />
            </StyledComponents.MyButton>
          </StyledComponents.SpecialContainer>
        )}
      </Container>
    </>
  );
};
