import { AccountingPolicyForm  } from "../../components-polizas";
import { RedirectSelects } from "../../components-integrity";


export const AccountingPolicyModule = () => {
  RedirectSelects();
  return (
    <>
      <AccountingPolicyForm />
    </>
  );
};

